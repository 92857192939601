import { Switch, Route } from "react-router-dom";
import ConsumerMenu from "./consumer/ConsumerMenu";
import ConsumerProduct from "./consumer/ConsumerProduct";
import ConsumerProductView from "./consumer/ConsumerProductView";
import CategoryListPage from "../pages/CategoryListPage";
import CategoryProductListPage from "../pages/CategoryProductListPage";

//import Cat2 from "./admin/cat2/Cat2";
import Contact from "./consumer/Contact";
import { useState } from "react";

import "./ConsumerHome.css";

function ConsumerHome() {
  const [counter, setCounter] = useState(0);
  const [admin, setAdmin] = useState(false);

  function adminCounter() {
    //console.log(counter);
    setCounter(counter + 1);
    if (counter === 10) {
      setAdmin(true);
    }
  }

  return (
    <div>
      <div>
      <header className="w3-display-container w3-wide bgimg" id="home">
        <div className="w3-display-middle w3-text-black w3-center">
          <h1 onClick={adminCounter} className="w3-jumbo  w3-cursive">Verona Caney</h1>
          <h2 onClick={adminCounter}>Gardens & Landscaping</h2>
        </div>
      </header>
      </div>

      <ConsumerMenu admin={admin} />
      <Switch>
        <Route path="/products">
          <ConsumerProduct />
        </Route>
        <Route path="/product/:id">
          <ConsumerProductView />
        </Route>
        <Route path="/categories">
          <CategoryListPage />
        </Route>
        <Route path="/category/:id/:name">
          <CategoryProductListPage />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/">
          <ConsumerProduct />
        </Route>
      </Switch>

      <footer className="w3-container w3-padding-64 w3-center w3-opacity w3-light-grey w3-xlarge">
        Verona Caney Gardens & Landscaping
      </footer>
    </div>
  );
}

export default ConsumerHome;