import { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./UploadForm.css";
import axios from "axios";
import ProductImage from "./ProductImage";
import { Redirect } from "react-router-dom";

function UploadForm(props) {
  const [imageFile, setImageFile] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 16 / 16,
  });
  const [croppedImageUrl, setCromppedImageUrl] = useState(null);
  const [productRedirect, setProductRedirect] = useState(false);

  function uploadFile() {
    console.log("Uploading");
    console.log(imageFile);
    const formData = new FormData();
    formData.append("id", props.id);
    formData.append("photo", imageFile);

    axios
      .post("/admin/api/uploadfile.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(percentCompleted);
        },
      })
      .then((result) => {
        console.log(result);
        setSrc(null);
        setCromppedImageUrl(null);
        //window.location.reload();
        setProductRedirect(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      //let originalPanel = document.getElementById("originalPanel");
      //+originalPanel.scrollIntoView();
    }
  }

  function onImageLoaded(image) {
    setImageRef(image);
  }

  function onCropComplete(crop) {
    makeClientCrop(crop);
  }

  async function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        props.id + ".jpeg"
      );
      setCromppedImageUrl(croppedImageUrl);
    }
  }

  function onCropChange(crop, percentCrop) {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    //canvas.setAttribute('id', "imagePreview");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    setImageFile(canvas.toDataURL("image/jpeg"));

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          let fileUrl = null;
          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  }
  if (productRedirect) {
    return <Redirect to="/admin/products" />;
  } else {
    return (
      <div>
        <div className="w3-panel w3-blue">Current Image</div>
        <ProductImage id={"/productimages/" + props.id + ".jpg"} max="300px" />
        <div className="w3-panel w3-yellow">Update Image</div>
        <div>
          <label htmlFor="productimage" className="w3-btn w3-yellow p-r-5">
            Select File
          </label>
          <input
            type="file"
            name="productimage"
            id="productimage"
            onChange={onSelectFile}
          />

          <button
            type="button"
            className="w3-btn w3-yellow"
            onClick={uploadFile}
          >
            Upload
          </button>
        </div>

        {src && (
          <div className="w3-half">
            <div id="originalPanel" className="w3-panel w3-green">
              Original
            </div>
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
            />
          </div>
        )}
        {croppedImageUrl && (
          <div className="w3-half">
            <div className="w3-panel w3-purple">Cropped</div>
            <img
              alt="Crop"
              style={{ maxWidth: "100%" }}
              src={croppedImageUrl}
            />
          </div>
        )}
      </div>
    );
  }
}

export default UploadForm;
