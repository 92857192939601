function DropdownEdit(props) {
  let options = props.options || [];
  return (
    <div>
      <label data-testid="DropdownEdit-label">{props.title}</label>
      <select
        name={props.key}
        data-testid="DropdownEdit-select"
        className="w3-input w3-border"
        value={props.val}
        onChange={(e) => {
          const tmp = {
            name: props.name,
            value: e.target.value,
          };
          props.dropChange(tmp);
        }}
      >
        {options.map((o) => (
          <option
            data-testid="DropdownEdit-option"
            key={Math.floor(Math.random() * 10000)}
            value={o.value}
          >
            {o.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropdownEdit;
