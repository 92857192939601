import axios from "axios";
import { useState, useEffect } from "react";

export default function useInventory({
  id = null,
  category = null,
  limit = null,
  offset = null,
  search = null,
}) {
  const [data, setData] = useState({ data: [{name1: ""}] });

  function getInventory(url) {
    axios.get(url, {}).then(
      (response) => {
        setData(response.data);
      },
      (error) => {
        setData([]);
        console.log(error);
      }
    );
  }

  useEffect(() => {
    let url = "/api/inventoryv2.php";
    let getParams = [];
    if (id) {
      getParams.push("id=" + id);
    }

    if (category && category !== "All") {
      getParams.push("category=" + category);
    }

    if (search && search.length > 3) {
      getParams.push("search=" + search);
    }

    if (limit || category === "All") {
      if (limit)
        getParams.push("limit=" + limit);
      else 
        getParams.push("limit=100");
    }

    if (offset) {
      getParams.push("last=" + offset);
    }
    url += "?" + getParams.join("&");
    getInventory(url);
  }, [id, category, search, limit, offset]);

  return data;
}
