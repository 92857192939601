import { useState, useEffect } from "react";
import axios from "axios";

export default function useCategoryList() {
  const [data, setData] = useState([]);

  function getCategories(url) {
    axios.get(url, {}).then(
      (response) => {
        setData(response.data);
      },
      (error) => {
        setData([]);
        console.log(error);
      }
    );
  }

  useEffect(() => {
    let url = "/api/category.php";
    getCategories(url);
  }, []);

  return data;
}
