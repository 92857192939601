import useCategoriesV2 from "../../../hooks/useCategoriesV2";
import { useParams, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
export default function CategoryEdit() {
  const { id } = useParams();
  const categoryData = useCategoriesV2({ id });
  const [data, setData] = useState({ name: "" });
  const [redirecter, setRedirecter] = useState(false);

  useEffect(() => {
    if (id && categoryData && categoryData.length > 0) {
      setData(categoryData[0]);
    }
  }, [id, categoryData]);

  function handleChange(e) {
    //Wrapping parse stringify to get a copy of object.
    let t = JSON.parse(JSON.stringify(data));

    console.log(e.target.name);
    t[e.target.name] = e.target.value;
    setData(t);
  }

  function handleSave() {
    let formData = new FormData();
    if (id) {
      formData.append("id", data.id);
    }
    formData.append("name", data.name);

    axios({
      method: "post",
      url: `/admin/api/category.php`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        setRedirecter(true);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  function handleDelete() {
    let formData = new FormData();
    if (id) {
      formData.append("id", data.id);
      formData.append("action", "delete");
    }
    //console.log("Submitting Form");
    axios({
      method: "POST",
      url: `/admin/api/category.php`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        console.log(result.data.success);
        setRedirecter(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      {redirecter && <Redirect to="/admin/categories" />}
      <h3>Category Edit</h3>
      <label>Name</label>
      <input
        type="text"
        name="name"
        className="w3-input"
        onChange={handleChange}
        value={data.name}
      />
      <div className="w3-btn w3-blue" onClick={handleSave}>
        Save
      </div>

      <div className="w3-btn w3-red" onClick={handleDelete}>
        Delete
      </div>
    </div>
  );
}
