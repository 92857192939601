import './ProductImage.css'
function ProductImage(props) {
  function handleEmptyImage(event) {
    event.target.src = "/images/placeholder.png";
  }

  if (props.max) {
    return (
      <img
        src={props.id}
        alt={props.id}
        onError={handleEmptyImage}
        loading="lazy"
        style={{
          maxWidth: props.max,
        }}
      />
    );
  } else {
    return (
      <img
        src={props.id}
        alt={props.id}
        onError={handleEmptyImage}
        loading="lazy"
        style={{ width: "85%", objectFit:"contain", aspectRatio: "1/1"}}
      />
    );
  }
}

export default ProductImage;

/*
style={{
        width: "100%",
        maxWidth: props.maxWidth ? props.maxWidth : "300px",
      }}
      */
