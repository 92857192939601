import CrudList from "../../basic/CrudList";
import useCategoriesV2 from "../../../hooks/useCategoriesV2";
export default function Categories(props) {
  const categoryData = useCategoriesV2({});
  return (
    <div>
      <h3>Categories</h3>
      <CrudList editpath="/admin/categoryedit/" data={categoryData} />{" "}
    </div>
  );
}
