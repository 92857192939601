import useCategoriesV2 from "../../hooks/useCategoriesV2";
import DropdownEdit from "./DropdownEdit";

function CategoryDropdown(props) {
  const categories = useCategoriesV2({});
  categories.forEach((c) => {
    c.value = c.id;
  });
  return <DropdownEdit {...props} options={categories} />;
}

export default CategoryDropdown;
