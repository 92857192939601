import { Link } from "react-router-dom";
function ConsumerMenu(props) {
  const items = [
    { name: "Home", to: "/" },
    { name: "Products", to: "/products" },
    { name: "Categories", to: "/categories" },
    //{ name: "PDF", to: "/catalog" },
    { name: "Contact", to: "/contact" },
  ];

  return (
    <div>
      <nav className="w3-bar w3-black">
        {items.map((i) => (
          <Link
            to={i.to}
            key={"menu_" + i.name}
            className="nav-link w3-button w3-bar-item"
          >
            {i.name}
          </Link>
        ))}
        {props.admin && (
          <Link
            to="/admin"
            className="nav-link w3-button w3-bar-item"
          >
            Admin
          </Link>
        )}
      </nav>
    </div>
  );
}

export default ConsumerMenu;
