import React, { useState, useEffect } from "react";
import FilterOptions from "../../form/FilterOptions";
import CategoryFilterOptions from "../../form/CategoryFilterOptions";
import useInventory from "../../../hooks/useInventory";
import ProductCard from "./ProductCard";

function Products() {
  const [nameFilter, setNameFilter] = useState("");
  const [catFilter, setCatFilter] = useState(
    localStorage.getItem("catFilter") || "All"
  );

  const data = useInventory({
    category: catFilter,
    search: nameFilter,
  });
  const [locations, setLocations] = useState(["GH1"]);
  const [locFilter, setLocFilter] = useState("All");

  const [filterData, setFilterData] = useState([]);

  function sortCmp(a, b) {
    return a['name1'].localeCompare(b['name1'])
  }

  useEffect(() => {

    let tmp = data?.data;

    let newLocations=[];
    tmp.forEach(i => {
      if (!newLocations.includes(i.location)) {
        newLocations.push(i.location);
      }
    })
    setLocations(newLocations);

    if (locFilter !== "All"){
      tmp = tmp.filter(i => {
        return i.location?.toUpperCase() === locFilter.toUpperCase();
      })
    }

    tmp.sort(sortCmp)

    setFilterData(tmp);
  }, [catFilter, nameFilter, locFilter, data]);

  function setCat(val) {

    setCatFilter(val)
  }

  return (
    <div className="w3-responsive">
      <table className="w3-table w3-bordered w3-striped">
        <thead>
          <tr>
            <th scope="col">
              Name 1 / 2 <br />
              <input
                type="text"
                onChange={(e) => setNameFilter(e.target.value)}
                value={nameFilter}
              />
            </th>
            <th scope="col" className="d-none d-lg-table-cell">
              Size
            </th>
            <th scope="col" className="d-none d-lg-table-cell">
              Category{" "}
              <CategoryFilterOptions
                onChange={(e) => {
                  setCat(e);
                }}
              />
            </th>
            <th scope="col" className="d-none d-lg-table-cell">
              Location
              <FilterOptions
                onChange={(e) => setLocFilter(e.target.value)}
                list={locations}
                label="Location"
                value={locFilter}
              />
            </th>
            <th scope="col" className="d-none d-lg-table-cell">
              Cost / Qty
            </th>
          </tr>
        </thead>
        <tbody>
          {filterData?.map((item) => (
            <ProductCard key={"productcard-" + item.id} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Products;
