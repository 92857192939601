import InputEdit from "./InputEdit";
import DropdownEdit from "./DropdownEdit";
import CategoryDropdown from "./CategoryDropdown";

function FormField(props) {
  switch (props.type) {
    case "text":
      return <InputEdit {...props} />;
    case "number":
      return <InputEdit {...props} />;
    case "password":
      return <InputEdit {...props} />;
    case "dropdown":
      return <DropdownEdit {...props} />;
    case "category":
        return <CategoryDropdown {...props} />;
    default:
      return <div>{props.title}</div>;
  }
}

export default FormField;
