import ProductImage from "../form/ProductImage";
import { Link } from "react-router-dom";

export default function ConsumerProductCard(props) {
    const product = props.product;
    return (
        <div className="w3-col s12 m4 l3" key={product.id}>
          <div className="w3-card w3-margin">
            <div className="w3-margin-left long-text">
              <Link to={`/product/${product.id}`}>{product.name1}</Link>
            </div>
            <div className="w3-margin-left long-text">
              <Link to={`/product/${product.id}`}>{product.name2}</Link>
            </div>
            <div>
              <center>
              <ProductImage id={product.imageUrl} />
              </center>
            </div>
            <div className="w3-margin product-cost">
              <Link className="w3-btn w3-blue" to={`/product/${product.id}`}>
                ${Number(product.cost).toFixed(2)}
              </Link>
            </div>
          </div>
        </div>
    )
}