import { Link } from "react-router-dom";
import { lazy, Suspense } from 'react';
const ProductImage = lazy(() => import("../../form/ProductImage"))
// import ProductImage from "../../form/ProductImage";
export default function ProductCard(props) {
  let item = props.item;
  return (
    <tr key={item.id}>
      <td>
        <Link to={"/admin/productedit/" + item.id}>
          <Suspense fallback={<div></div>}>
            <ProductImage id={item.imageUrl} max="50px" />
          </Suspense>
        </Link>
        &nbsp;
        <Link to={"/admin/productedit/" + item.id}>
          {item.name1} / {item.name2}
        </Link>
      </td>
      <td className="d-none d-lg-table-cell">
        {item.size} {item.unit}
      </td>
      <td className="d-none d-lg-table-cell">{item.category?.toUpperCase()}</td>
      <td className="d-none d-lg-table-cell">{item.location?.toUpperCase()}</td>
      <td className="d-none d-lg-table-cell">
        $ {Number(item.cost).toFixed(2)} <br/>
        Qty: {Number(item.qty)}
      </td>
    </tr>
  );
}
