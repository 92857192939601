import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductImage from "../form/ProductImage";
import ReactMarkdown from "react-markdown";
import useInventory from "../../hooks/useInventory";
import useDescription from "../../hooks/useDescription";

function ConsumerProductView() {
  const { id } = useParams();
  const inventory = useInventory({ id: id });
  const description = useDescription({ id: id });
  const [data, setData] = useState([]);
  const [txt, setTxt] = useState("");
  useEffect(() => {
    setData(inventory.data[0]);
    function getDesctiptionText(d) {
      console.log(d.length);
      if (d.length > 0) {
        return d[0].description;
      } else {
        return "";
      }
    }

    setTxt(getDesctiptionText(description));
  }, [id, inventory, description]);

  return (
    <div className="w3-container">
      <div>
        {" "}
        <h1 className="cap-text">{data.name1}</h1>
        <h2 className="cap-text">{data.name2}</h2>
      </div>
      <div className="w3-third">
        <ProductImage id={data.imageUrl} />
        <div>
          <br />
          <p><b>Category: </b>{data.category}</p>
          <p><b>Size: </b>{data.size} {data.unit}</p>
          <div className="w3-btn w3-blue w3-xlarge">
            ${Number(data.cost).toFixed(2)}
          </div>
        </div>
        <h5>How To Order</h5>
        <div>
          We are a wholesale nursery, that sells to the public. To order give us
          a call and we will create you an order and ensure availability.
          <br />
          <a className="w3-btn" href="tel://9313591126">
            (931) 359-1126
          </a>
        </div>
      </div>
      <div className="w3-twothird">
        <ReactMarkdown>{txt}</ReactMarkdown>
      </div>
    </div>
  );
}

export default ConsumerProductView;
