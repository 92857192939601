import React, { useContext, createContext, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
const authContext = createContext();

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (cb) => {
    return fakeAuth.signin(() => {
      setUser("user");
      cb();
    });
  };

  const signout = (cb) => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  const validate = () => {
    return axios.get("/admin/api/authcheck.php").then(
      (response) => {
        //console.log(response);
        setUser("user");
      },
      (error) => {
        setUser(null);
        console.log(error);
      }
    );
  };

  return {
    user,
    signin,
    signout,
    validate,
  };
}

export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    auth.validate().then((d) => {
      setSpinner(false);
    });
  });

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <div>
            {!spinner && (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )}
          </div>
        )
      }
    />
  );
}
