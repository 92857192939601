import React, { useState } from "react";
import FilterOptionsId from "./FilterOptionsId";
import useCategoriesV2 from "../../hooks/useCategoriesV2";

function CategoryFilterOptions(props) {
  const categories = useCategoriesV2({});
  const [catFilter, setCatFilter] = useState(
    localStorage.getItem("catFilter") || "All"
  );

  return (
    <FilterOptionsId
      onChange={(e) => {
        localStorage.setItem("catFilter", e.target.value);
        setCatFilter(e.target.value);
        props.onChange(e.target.value);
      }}
      list={categories}
      label="Category"
      value={catFilter}
    />
  );
}

export default CategoryFilterOptions;
