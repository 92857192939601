import "./App.css";

import { HashRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import ConsumerHome from "./components/ConsumerHome";
import AdminHome from "./components/admin/AdminHome";
import Login from "./components/admin/login/Login";

import { ProvideAuth, PrivateRoute } from "./Authentication";

function App(props) {
  return (
    <ProvideAuth>
      <Router basename="/">
        <div>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute path="/admin">
              <AdminHome />
            </PrivateRoute>
            <Route path="/">
              <ConsumerHome />
            </Route>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}

export default App;
