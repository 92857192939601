function FilterOptionsId(props) {
  return (
    <select
      name={props.label}
      className="form-select"
      aria-label={props.label}
      onChange={props.onChange}
      value={props.value}
    >
      <option value="All">All</option>
      {props.list.map((i) => (
        <option key={props.label + i.id} value={i.id}>
          {i.name}
        </option>
      ))}
    </select>
  );
}

export default FilterOptionsId;
