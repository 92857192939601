import React from "react";
import useCategoryList from "../hooks/useCategoryList";
import { Link } from "react-router-dom";

export default function CategoryListPage() {
  const categories = useCategoryList();

  return (
    <ul>
      {categories.map((cat) => (
        <li key={cat.id}>
          <Link to={`/category/${cat.id}/${cat.name}`}>{cat.name}</Link>
        </li>
      ))}
    </ul>
  );
}
