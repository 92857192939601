import { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import axios from "axios";
import ProductFormSchema from "./ProductFormSchema";
import FormView from "../../form/FormView";
import UploadForm from "../../form/UploadForm";
import Editor from "rich-markdown-editor";


function ProductEdit(props) {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [description, setDescription] = useState("");
  const [editorDescription, setEditorDescription] = useState("");
  const [redirecter, setRedirecter] = useState(false);

  function handleChange(e) {
    //Wrapping parse stringify to get a copy of object.
    let t = JSON.parse(JSON.stringify(data));
    console.log(e.target.name)
    t[e.target.name] = e.target.value;
    setData(t);
  }

  function dropChange(e) {
    //Wrapping parse stringify to get a copy of object.
    let t = JSON.parse(JSON.stringify(data));
    console.log(e)
    t[e.name] = e.value;
    console.log(t)
    setData(t);
  }

  function handleDelete() {
    //console.log("Submitting Form");
    axios({
      method: "POST",
      url: "/admin/api/deleteInventory.php",
      headers: { "content-type": "application/json" },
      data: data,
    })
      .then((result) => {
        console.log(result.data.success);
        setRedirecter(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSubmit() {
    //console.log("Submitting Form");
    axios({
      method: "POST",
      url: "/admin/api/putInventory.php",
      headers: { "content-type": "application/json" },
      data: data,
    })
      .then((result) => {
        console.log(result.data.success);
        setRedirecter(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCancel() {
    setRedirecter(true);
  }

  function saveDescription() {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("description", editorDescription);

    axios({
      method: "post",
      url: `/admin/api/description.php`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  useEffect(() => {
    if (props.new) {
    } else {
      axios.get(`/api/inventory.php?id=${id}`).then(
        (response) => {
          setData(response.data[0]);
        },
        (error) => {
          console.log(error);
        }
      );

      axios.get(`/admin/api/description.php?id=${id}`).then(
        (response) => {
          if (response?.data?.length > 0) {
            setDescription(response.data[0].description);
          } else {
            setDescription("");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [props.new, id]);

  return (
    <div className="container">
      {redirecter && <Redirect to="/admin/products" />}
      <FormView
        formSchema={ProductFormSchema}
        edit={data}
        onChange={handleChange}
        dropChange={dropChange}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleDelete={handleDelete}
      />

      <UploadForm id={id} />
      <div className="w3-panel w3-green">Description</div>
      <div
        style={{
          border: "1px solid #ccc",
          marginTop: "16px",
          marginBottom: "100px",
        }}
      >
        <Editor
          value={description}
          onChange={(value) => {
            setEditorDescription(value);
          }}
        />
        <button className="w3-btn w3-blue" onClick={saveDescription}>Save</button>
      </div>
    </div>
  );
}

export default ProductEdit;
