import { Link } from "react-router-dom";
import Logout from "./Logout";

function Menu() {
  const items = [
    { name: "Home", to: "/admin" },
    //{ name: "Catalog", to: "/admin/catalog" },
    //{ name: "Cat2", to: "/admin/cat2" },
    { name: "Products", to: "/admin/products" },
    { name: "New", to: "/admin/productnew" },
  ];

  return (
    <nav className="w3-bar">
      <Link className="nav-link w3-button w3-bar-item" to="/">
        VCGL Admin
      </Link>
          {items.map((i) => (
              <Link key={"admin-menu-"+i.to} className="nav-link w3-button w3-bar-item" to={i.to}>
                {i.name}
              </Link>
          ))}
            <Logout />
    </nav>
  );
}

export default Menu;
