import { useState } from "react";
import FormField from "./FormField";

function AreYouSure(props) {
  return (
    <div className="alert alert-warning" role="alert">
      <p>Are you sure?</p>
      <button className="btn btn-danger m-2" onClick={props.onClick}>
        Yes
      </button>
      <button className="btn btn-secondary m-2" onClick={props.noClick}>
        No
      </button>
    </div>
  );
}

function FormView(props) {
  const formSchema = props.formSchema;
  const [deleteAlert, setDeleteAlert] = useState(false);

  return (
    <div>
      {Object.keys(formSchema)
        .filter((item) => formSchema[item].formDisplay)
        .map((item) => (
          <FormField
            key={item}
            title={formSchema[item].title}
            name={formSchema[item].name}
            val={props.edit[formSchema[item].name]}
            type={formSchema[item].type}
            dropChange={props.dropChange}
            onChange={props.onChange}
          />
        ))}
      {deleteAlert && (
        <AreYouSure
          onClick={props.handleDelete}
          noClick={() => {
            setDeleteAlert(false);
          }}
        />
      )}
      <br />
      <button className="w3-btn w3-blue p-r-5" onClick={props.handleSubmit}>
        Save
      </button>
      {props.hasOwnProperty("handleCancel") && (
        <button className="w3-btn w3-grey p-r-5" onClick={props.handleCancel}>
          Cancel
        </button>
      )}
      {props.hasOwnProperty("handleDelete") && (
        <button
          className="w3-btn w3-red p-r-5"
          onClick={() => {
            setDeleteAlert(true);
          }}
        >
          Delete
        </button>
      )}
    </div>
  );
}

export default FormView;
