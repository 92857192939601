const ProductFormSchema = {
    id: {
      title: "ID",
      name: "id",
      type: "text",
      tableDisplay: false,
      formDisplay: false,
    },
    name1: {
      title: "Name 1",
      name: "name1",
      type: "text",
      tableDisplay: true,
      formDisplay: true,
    },
    name2: {
      title: "Name 2",
      name: "name2",
      type: "text",
      tableDisplay: true,
      formDisplay: true,
    },
    cost: {
      title: "Cost",
      name: "cost",
      type: "number",
      tableDisplay: true,
      formDisplay: true,
    },
    size: {
      title: "Size",
      name: "size",
      type: "number",
      tableDisplay: true,
      formDisplay: true,
    },
    unit: {
      title: "Unit",
      name: "unit",
      type: "text",
      tableDisplay: true,
      formDisplay: true,
    },
    qty: {
      title: "Qty",
      name: "qty",
      type: "number",
      tableDisplay: true,
      formDisplay: true,
    },
    category: {
      title: "Category",
      name: "category",
      type: "text",
      tableDisplay: true,
      formDisplay: false,
    },
    category_id: {
      title: "Category",
      name: "category_id",
      type: "category",
      tableDisplay: false,
      formDisplay: true,
    },
    subcategory: {
      title: "Sub Category",
      name: "subcategory",
      type: "text",
      tableDisplay: true,
      formDisplay: true,
    },
    category3: {
      title: "Category 3",
      name: "category3",
      type: "text",
      tableDisplay: true,
      formDisplay: true,
    },
    location: {
      title: "Location",
      name: "location",
      type: "text",
      tableDisplay: true,
      formDisplay: true,
    },
    availability: {
      title: "Availability",
      name: "availability",
      type: "date",
      tableDisplay: true,
      formDisplay: true,
    },
    company_id: {
      title: "Company",
      name: "company_id",
      type: "text",
      tableDisplay: true,
      formDisplay: true,
    },
  };
  
  export default ProductFormSchema;
  