import { useState } from "react";
import useInventory from "../../hooks/useInventory";
import ConsumerProductCard from "./ConsumerProductCard";

function ConsumerProduct() {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const productLimits = 12;
  const data = useInventory({search: searchQuery, limit: productLimits, offset: page*productLimits})

  function getNext() {
    let TotalPages = Math.ceil(data.total/productLimits);
    console.log(TotalPages + "   " + page)
    if (page < TotalPages) {
      setPage(page + 1);
    }
  }

  function getPrev() {
    if (page > 0) {
      setPage(page - 1);
    } else {
      setPage(0);
    }
  }

  function getStart() {
    setPage(0);
  }
  
  function handleSearchChange(e) {
    console.log(e.target.value)
    setSearchQuery(e.target.value);
  }

  return (
    <div className="w3-container">
      <div className="w3-right-align">
        <label className="w3-label">Search </label>
        <input type="text" className="w3-border" onChange={handleSearchChange} value={searchQuery}/>
      </div>
      {data.data.map((product) => (
        <ConsumerProductCard key={"consumer-product-card-" + product.id} product={product} />
      ))}
      <div className="w3-right-align">
        Viewing Page {page+1} of {Math.ceil(data.total/productLimits)}
      </div>
      <div className="w3-right-align">
        <span className="w3-btn" onClick={getStart}>
          Start
        </span>
        <span className="w3-btn" onClick={getPrev}>
          Previous
        </span>
        <span className="w3-btn" onClick={getNext}>
          Next
        </span>
      </div>
    </div>
  );
}

export default ConsumerProduct;
