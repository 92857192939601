import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./login/Login";
import Menu from "./Menu";
import Products from "./products/Products";
import ProductEdit from "./products/ProductEdit";
import Logout from "./Logout";
import Categories from "./categories/Categories";
import CategoryEdit from "./categories/CategoryEdit"
//import Catalog from "./catalog/Catalog";
//import Cat2 from "./cat2/Cat2";

function AdminHome(props) {
  return (
    <div className="w3-content">
      <Menu />

      <Switch>
        <Route path="/admin/login">
          <Login />
        </Route>
        <Route path="/admin/products">
          <Products />
        </Route>
        <Route path="/admin/productedit/:id">
          <ProductEdit new={false} />
        </Route>
        <Route path="/admin/productnew">
          <ProductEdit new={true} />
        </Route>
        <Route path="/admin/categories">
          <Categories />
        </Route>
        <Route path="/admin/categoryedit/:id">
          <CategoryEdit new={false} />
        </Route>
        <Route path="/admin/categorynew">
          <CategoryEdit new={true} />
        </Route>
        <Route path="/admin/logout">
          <Logout logout={props.logout} />
        </Route>
        <Route path="/admin">
          <Home />
        </Route>
      </Switch>
    </div>
  );
}

export default AdminHome;
/*
        <Route path="/admin/catalog">
          <Catalog />
        </Route>
        <Route path="/admin/cat2">
          <Cat2 />
        </Route>
        */