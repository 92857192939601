const LoginFormSchema = {
    username: {
      title: "Username",
      name: "username",
      type: "text",
      tableDisplay: false,
      formDisplay: true,
    },
    password: {
      title: "Password",
      name: "password",
      type: "password",
      tableDisplay: false,
      formDisplay: true,
    }
  };
  
  export default LoginFormSchema;
  