import LoginFormSchema from "./LoginFormSchema";
import FormView from "../../form/FormView";
import axios from "axios";
import errorhandler from "../../AxiosHelper";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../../Authentication";

function Login(props) {
  let history = useHistory();
  let location = useLocation();

  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  //const [next, setNext] = useState(false);
  let auth = useAuth();
  let { from } = location.state || { from: { pathname: "/admin" } };

  function handleChange(e) {
    //Wrapping parse stringify to get a copy of object.
    let t = JSON.parse(JSON.stringify(data));
    t[e.target.name] = e.target.value;
    setData(t);
  }

  function handleSubmit() {
    //console.log("Submitting Form");
    axios({
      method: "POST",
      url: "/admin/api/auth.php",
      headers: { "content-type": "application/json" },
      data: data,
    })
      .then((result) => {
        console.log(result.data.success);
        if (result.data.success) {
          setError(false);
          auth.signin(() => {
            history.replace(from);
          });
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        errorhandler(err);
        setError(true);
      });
  }

  return (
    <div>
      Login
      <FormView
        formSchema={LoginFormSchema}
        edit={data}
        onChange={handleChange}
        handleSubmit={handleSubmit}
      />
      {error && <div className="w3-panel w3-red">Error on login.</div>}
    </div>
  );
}

export default Login;
