import { Link } from "react-router-dom";

export default function CrudList(props) {
  return (
    <div>
      {props.data.map((item) => (
        <div key={"crudlist-"+item.id}>
          <Link to={props.editpath + item.id}>{item.name}</Link>
        </div>
      ))}
    </div>
  );
}
