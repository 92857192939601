//import {Link} from "react-router-dom";
import { Link } from "react-router-dom";
function Home() {
  return (
    <div className="w3-content">
          <h3>Products</h3>
          <ul>
            <li><Link to={"/admin/products"}>List</Link></li>
            <li><Link to={"/admin/productnew"}>New</Link></li>
            <li><a href="/api/table.php" target="_blank" rel="noopener noreferrer">Full List</a></li>
          </ul>

          <h3>Categories</h3>
          <ul>
            <li><Link to={"/admin/categories"}>List</Link></li>
            <li><Link to={"/admin/categorynew"}>New</Link></li>
          </ul>
    </div>
  );
}

export default Home;
