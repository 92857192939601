import { memo } from "react";

const ListProductImage = memo(function ListProductImage({id, max}) {
  function handleEmptyImage(event) {
    event.target.src = "/images/placeholder.png";
  }

  if (max) {
    return (
      <img
        src={id}
        alt={id}
        onError={handleEmptyImage}
        loading="lazy"
        style={{
          maxWidth: max,
        }}
      />
    );
  } else {
    return (
      <img
        src={id}
        alt={id}
        onError={handleEmptyImage}
        loading="lazy"
        className="w3-bar-item"
        style={{ width: "100px", objectFit: "contain", aspectRatio: "1/1" }}
      />
    );
  }
});
export default ListProductImage;
