import axios from "axios";
import { useState, useEffect } from "react";

export default function useDescription({ id = null }) {
  const [data, setData] = useState([]);

  function getDescription(id) {
    axios.get(`/api/description.php?id=${id}`, {}).then(
      (response) => {
        setData(response.data);
        //setData("")
      },
      (error) => {
        setData("");
        console.log(error);
      }
    );
  }

  useEffect(() => {
    getDescription(id);
  }, [id]);

  return data;
}
