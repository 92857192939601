import { useAuth } from "../../Authentication";
import { useHistory } from "react-router-dom";

function Logout() {
  let history = useHistory();
  let auth = useAuth();
  return (
    <span href="#"
    className="nav-link w3-button w3-bar-item"
      onClick={() => {
        auth.signout(() => {
          history.push("/");
        });
      }}
    >Logout</span>
  );
}

export default Logout;
