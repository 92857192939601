import React from "react";
//import { memo } from 'react';
import { useParams } from "react-router-dom";
import useInventory from "../hooks/useInventory";
import ListProductImage from "../components/ListProductImage";
import { Link } from "react-router-dom";

export default function CategoryProductListPage() {
  const { id, name } = useParams();
  //const productLimits = 12;
  const inventory = useInventory({ category: id });

  function sortCmp(a, b) {
    return a['name1'].localeCompare(b['name1'])
  }

  let data = inventory.data;

  data = data.sort(sortCmp)
  return (
    <div className="w3-container" style={{ margin: 'auto', maxWidth: 1000}}>
      <h1>Category: {name}</h1>
      <ul>
        {data.map((item) => (
          <li key={item.id + item.name} className="w3-bar cat-list">
            <ListProductImage id={item.imageUrl} />
            <div className="w3-bar-item">
              <p className="w3-large" style={{overflow: "hidden", maxWidth:"15ch"}}>
                <Link to={`/product/${item.id}`}>{item.name1}</Link>
              </p>
              <p style={{overflow: "hidden", maxWidth:"21ch"}}>
                {item.name2}
              </p>
              <div className="w3-hide-medium w3-hide-large">
                <span className="w3-large">
                  <b>Cost: </b>$ {Number(item.cost).toFixed(2)}
                </span>
                <br />
                <span>
                  <b>Size: </b> {item.size} {item.unit}{" "}
                </span>
              </div>
            </div>
            <div className="w3-bar-item w3-mobile w3-right w3-hide-small">
              <span className="w3-large">
                <br/>
                <b>Cost: </b>$ {Number(item.cost).toFixed(2)}
              </span>
              <br />
              <span>
                <b>Size: </b> {item.size} {item.unit}{" "}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
