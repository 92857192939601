function Contact() {
  return (
    <div className="w3-container">
      <div className="w3-third">
        <h2>Contact Us</h2>
        <p>
          <b>Address</b>
          <br />
          VERONA CANEY GARDENS & LANDSCAPING
          <br />
          2975 VERONA CANEY ROAD
          <br />
          LEWISBURG, TN 37091
          <br />
        </p>
        <p>
          <b>Phone</b>
          <br />
          PHONE/FAX 931-359-1121
          <br />
          TEXT/CELL 931-359-1126
          <br />
        </p>
      </div>
      <div className="w3-twothird">
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3245.8200967503008!2d-86.7754932435885!3d35.558145035009225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886398513a785a61%3A0x2d570eea6523fbab!2sVerona%20Caney%20Garden%20%26%20Landscaping!5e0!3m2!1sen!2sus!4v1636062482808!5m2!1sen!2sus"
          width="100% "
          height="450"
          style={{ border: 0, maxWidth: "600px" }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
