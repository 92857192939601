import { useState, useEffect } from "react";
import axios from "axios";

export default function useCategoriesV2({ id = null }) {
  const [data, setData] = useState([]);

  function getCategories(url) {
    axios.get(url, {}).then(
      (response) => {
        setData(response.data);
      },
      (error) => {
        setData([]);
        console.log(error);
      }
    );
  }

  useEffect(() => {
    let url = "/admin/api/category.php";
    let getParams = [];
    if (id) {
      getParams.push("id=" + id);
    }
    url += "?" + getParams.join("&");
    getCategories(url);
  }, [id]);

  return data;
}
